.App {
  text-align: center;
}

.navbardesign {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  z-index: 999;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.backgroundImage {
  background-image: url(./Images/Ace4DOriginal6.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: black;
  /* background-color: #000000; */
}

.backgroundImage2 {
  background-image: url(./Images/lightBlue_background.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.backgroundImage3 {
  background-image: url(./Images/lightpurple_background.png);
  background-position: center;
  background-size: contain;
  background-repeat: repeat;
  margin-bottom: 0;
}

.backgroundImage4 {
  background-image: url(./Images/lightpurple_background.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #ccccff;
}

.logoiconbutton {
  border: none;
  background: none;
  outline: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.halo {
  margin-bottom: 0px !important;
}

.divmain {
  background-color: "blue";
}

.table4D {
  background-color: #007bff;
}
.tablegrey {
  background-color: #787d81;
}

:root {
  --mainDark: #343a40;
  --mainGrey: rgba(109, 109, 109);
  --mainLightGrey: rgba(172, 172, 172);
  --mainWhite: #fff;
  --mainBlue: #007bff;
}

/* Past Result (border2 css)*/
.borderclass {
  border-radius: 8px;
  background-color: #414141;
  height: 30px;
}

.totoborderclass {
  border-radius: 8px;
  background-color: #ff431b;
  height: 40px;
  font-size: 18px;
}

.bordermagnumclass {
  border-radius: 8px;
  background-color: #fff23e;
  height: 40px;
  font-size: 18px;
}
.bordersingaporeclass {
  border-radius: 8px;
  background-color: #32c6f4;
  height: 40px;
  font-size: 18px;
}
.damacaiborderclass {
  border-radius: 8px;
  background-color: #414bb2;
  height: 40px;
  font-size: 18px;
}
.americaborderclass {
  border-radius: 8px;
  background-color: #6d100e;
  height: 40px;
  font-size: 18px;
}
.dragonborderclass {
  border-radius: 8px;
  background-color: #c1272d;
  height: 40px;
  font-size: 18px;
}
.sandagenborderclass {
  border-radius: 8px;
  background-color: #f8c100;
  height: 40px;
  font-size: 18px;
}
.sabahborderclass {
  border-radius: 8px;
  background-color: #fc0404;
  height: 40px;
  font-size: 18px;
}
.sarawakborderclass {
  border-radius: 8px;
  background-color: #008000;
  height: 40px;
  font-size: 18px;
}

.booms4borderclass {
  border-radius: 8px;
  background-color: #6d100e;
  height: 30px;
  font-size: 18px;
}

/* 4D History (border css)*/
.Historyborder {
  border-radius: 8px;
  background-color: #9cceff;
}
.Historyborder3 {
  border-radius: 8px;
  background-color: #9cceff;
}
.Historyborder2 {
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  background-color: #484646;
}
.Hislogoimages {
  width: 100px;
  height: 100px;
}

/* DamacaiJackPot*/
.Animallogo {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.Animallogo2 {
  width: 100px;
  height: 120px;
}
.zodiacfont {
  font-size: 16px;
}
.zodiacspaces {
  margin-top: auto;
}

/* Past Result (border css)*/
.numberborder {
  padding: 10px 0;
  border-radius: 20px;
  background-color: #eeeeee;
  font-size: 32px;
}
.numberborderdspecon {
  padding: 10px 0;
  border-radius: 20px;
  background-color: #eeeeee;
  font-size: 24px;
}
.number5Dborderdspecon {
  padding: 10px 0;
  border-radius: 20px;
  background-color: #eeeeee;
  font-size: 20px;
}

.numberborderPast {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  border-radius: 20px;
  background-color: #eeeeee;
  /* margin-left: 50 !important;
  margin-right: 50 !important; */
}

.ScreenAdjust {
  width: 200px;
}

.numberborderPastforerror {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  border-radius: 20px;
  border-color: #ff0000 !important;
  background-color: #ffffff;
  border-style: solid;
  border-width: 2px;
}

.numberborderPastMobile {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 40px 0;
  border-radius: 20px;
  display: flex;
  background-color: #eeeeee;
}
.datetimeborder {
  padding: 5px 5px;
  background-color: #9cceff;
}
.numberbordergorjackport {
  border-radius: 0px;
  background-color: #eeeeee;
  font-size: 24px;
}

.numberbordergorjackport2 {
  border-radius: 15px;
  background-color: #eeeeee;
}
.numberborder2 {
  padding: 10px 0;
  border-radius: 8px;
  background-color: #eeeeee;
  font-size: 28px;
}
.totoborder {
  padding: 10px 0;
  border-radius: 8px;
  background-color: #ff431b;
}
.singaporeborder {
  padding: 10px 0;
  border-radius: 8px;
  background-color: #32c6f4;
}
.americaborder {
  padding: 10px 0;
  border-radius: 8px;
  background-color: #6d100e;
  font-size: 18px;
}
.americaborder5D2 {
  padding: 10px 0;
  /* border-radius: 8px; */
  background-color: #6d100e;
  font-size: 18px;
}
.magnumborder {
  padding: 10px 0;
  border-radius: 8px;
  background-color: #fff23e;
}
.damacaiborder {
  padding: 10px 0;
  border-radius: 8px;
  background-color: #414bb2;
}
.draganborder {
  padding: 10px 0;
  border-radius: 8px;
  background-color: #c1272d;
}
.sandagenborder {
  padding: 10px 0;
  border-radius: 8px;
  background-color: #f8c100;
}
.sabahborder {
  padding: 10px 0;
  border-radius: 8px;
  background-color: #fc0404;
}
.sarawakborder {
  padding: 10px 0;
  border-radius: 8px;
  background-color: #008000;
}

/* JackPot*/
.jackpotborder {
  border-radius: 5px;
  height: 40px;
  background-color: #003986;
}

.booms4border {
  border-radius: 5px;
  background-color: #6d100e;
}

/* Dictionary*/
.Diclogoimages {
  width: 50px;
  height: 50px;
}

.Diclogoimages1 {
  width: 40px;
  height: 40px;
}
.Marginout {
  margin: 0 140px;
  flex-direction: row;
}

.Diclogoimages2 {
  width: 120px;
  height: 120px;
}

.Dictionaryborder {
  border-radius: 5px;
  height: 40px;
  background-color: #484646;
}

.DictionaryMagnumborder {
  border-radius: 5px;
  height: 40px;
  background-color: #fff23e;
}

.DictionaryTotomborder {
  border-radius: 5px;
  height: 40px;
  background-color: #fc0404;
}

.DictionaryDamacaiborder {
  border-radius: 5px;
  height: 40px;
  background-color: #414bb2;
}

.Dicdamacaiborder {
  border-radius: 8px;
  background-color: #414bb2;
  color: white;
}

.Dicmagnumborder {
  border-radius: 8px;
  background-color: #fff23e;
  color: black;
}

.Dictotoborder {
  border-radius: 8px;
  background-color: #fc0404;
  color: white;
}

.numberborderDic {
  padding: 10px 0;
  border-radius: 100px;
  background-color: #e6e6e6;
}

.numberborderDic2 {
  width: 100%;
  padding: 10px 0;
  border-radius: 30px;
  background-color: #ffffff;
}

.noneButton {
  background-color: none;
  color: none;
}

.redButton {
  background-color: #0066ff;
  color: #0066ff;
  box-shadow: #0066ff;
}

.damaButton {
  background-color: #0066ff;
  color: #0066ff;
}

.yellowButton {
  background-color: #0066ff;
  color: #0066ff;
}

.DictionaryButtonborder {
  /* border: none; */
  outline: none;
  border-radius: 10px;
  padding: 5px 5px;
  background-color: #9cceff;
}

.DictionaryButtonborder22 {
  /* border: none; */
  outline: none;
  border-radius: 10px;
  padding: 4px 4px;
  background-color: #9cceff;
}

/* Past Result (logo images)*/
.logoimages {
  width: 80px;
  height: 80px;
}
.logomagnumimages {
  /* width: 8.1vw;
  height: 4.3vw; */
  width: 150px;
  height: 80px;
}
/* .logomagnumimages22 {
  width: 80px;
  height: 150;
} */
.textalignment {
  margin-top: 30px;
  font-size: 23px;
  font-weight: bold;
}
.textalignmen3t {
  padding: 10px 0;
  font-size: 21px;
  font-weight: bold;
}
.textalignment2 {
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-control {
  width: 12%;
}

.testingcolumn {
  display: flex;
  width: calc(100% / 3);
}

.selecteddate {
  line-height: 1.5;
}

.logohome {
  margin-right: 5px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Headerlogo {
  width: 130px;
  height: 90px;
}

/* Footer (footer css)*/
.main-footer {
  left: 0;
  bottom: 0;
  right: 0;
  padding-bottom: 0px;
}

.Footerimages {
  width: 200px;
  height: 60px;
}

.main-footer .footer-bottom {
  background: var(--mainDark);
  padding-top: 3rem;
  color: var(--mainWhite);
}

.main-footer .footer-bottom {
  padding-top: 3rem;
  padding-bottom: 2rem;
}

.main-footer ul li a {
  color: var(--mainGrey);
}

.main-footer ul li a:hover {
  color: var(--mainLightGrey);
}

.languagePicker {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 0px;
  margin-right: 20px;
}

.languagePicker label {
  margin-bottom: 0;
  margin-right: 10px;
}

.languagePicker .form-control {
  width: fit-content;
  height: fit-content;
  padding: 0px 10px;
}

.dcc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.datePickerButton {
  background-color: #9dcefe;
  border-radius: 8px;
  border-color: #5eadff;
  width: 80%;
  outline: none;
  padding: 5px 5px;
}

.nearDateButton {
  background-color: #0ca789;
  border-radius: 8px;
  border: none;
  outline: none;
  height: 30px;
  /* padding: 10px 10px; */
  padding: 30px 0;
}

.refreshButton {
  background-color: #52d017;
  border: none;
  outline: none;
  padding: 5px 20px;
  border-radius: 10px;
  color: #333;
  box-shadow: 0px 0px 3px;
}

.react-datepicker-wrapper input {
  text-align: center;
}

.datePickerButton:focus {
  outline: none;
}

.introductionCard {
  margin: 10px 0px;
  font-size: 20px;
  border-radius: 10px;
}

.introductionCardHeader {
  background-color: #78b2ee;
  text-align: left;
  padding: 10px;
  color: white;
}

.introductionCardBody {
  background-color: #dad8d8;
  text-align: center;
  padding: 10px;
}

.snackbar.error {
  background-color: red;
}

.snackbar.success {
  background-color: green;
}

.activeResult {
  border: 5px solid rgb(60, 252, 60);
  border-radius: 50%;
  -webkit-filter: grayscale(0%) !important;
  filter: grayscale(0%) !important;
}

.mainPageToggleLogo {
  height: 55px;
  width: 55px;
  cursor: pointer;
  -webkit-filter: grayscale(100%);
  filter: grayscale(1000%);
}

.mobileHeader {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.mobileHeader2 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.mobiledatefont {
  font-size: 12px !important;
  font-weight: bold !important;
}

.mobileHeaderDic {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15% !important;
  margin: 0;
  margin-right: 80;
}

.mobileHeaderDicMobile {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  margin: 0;
}

.mobileHeaderMo {
  padding-top: 0 !important;
}

.mobileHeaderMo1 {
  margin-top: -10;
}

.mobileHeader2 {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 90%;
  margin-left: 5%;
}

.Dictionarytypeborder {
  width: 100%;
}

.Dictionarytypebordermobile {
  width: 100% !important;
}

.mobileHeaderDictionary {
  width: 100% !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.mobileRefreshButton {
  margin: 20px;
}

.mobileCardContainer {
  margin: 0px !important;
}

.mobileToggleBar {
  margin-top: 20px;
  margin-bottom: 10px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.FourDBorder {
  margin-left: 50;
  margin-right: 50;
}

.scrollDesign {
  position: fixed;
  bottom: 20px;
  right: 3%;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #01a801;
  color: #ffffff;
  z-index: 1;
}

.iconpopup {
  animation: scrollTopPOP 0.5s alternate ease;
}

.FourDBorderMobile {
  margin-left: 0;
  margin-right: 0;
}

.mobile4D {
  width: 100% !important;
}

.mobile4D1 {
  width: 50%;
}

.mobile4D3 {
  justify-content: center !important;
  display: flex;
  margin-left: 40%;
}

.mobileCardLogo {
  width: 140px;
  height: 80px;
}

.mobileCardLogo2 {
  width: 130px;
  height: 70px;
  justify-content: center !important;
}

.mobileTextAlign {
  font-size: 14px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.mobileIntroductionCard {
  width: 90% !important;
  margin-left: 5% !important;
}

.mobileIntroductionFullCard {
  width: 100% !important;
  margin-left: -10px !important;
}

.mobileIntroductionText {
  font-size: 12px;
}

.mobileIntroductionSmallerText {
  font-size: 10px;
}

.mobileSmallerText {
  font-size: 14px;
}

.smallerAnimallogo {
  width: 20px;
  height: 20px;
}

.mobilefontCard1 {
  padding: 10px 0;
  border-radius: 20px;
  background-color: #eeeeee;
  font-size: 1.75vw;
}

.mobilefontCarddamacai {
  border-radius: 8px;
  background-color: #414bb2;
  height: 40px;
  font-size: 15px !important;
}

.mobilefontCard4 {
  padding: 10px 0;
  border-radius: 20px;
  background-color: #eeeeee;
  font-size: 1.33vw;
}

.mobilefontCard2 {
  padding: 10px 0;
  border-radius: 20px;
  background-color: #eeeeee;
  font-size: 20px !important;
}

.mobilefontCard3 {
  padding: 10px 0;
  border-radius: 20px;
  background-color: #eeeeee;
  font-size: 15px !important;
}

@media (max-width: 1800px) {
  .americaborderclass {
    font-size: 16px;
  }
  .logomagnumimages {
    width: 140px;
    height: 70px;
  }
  .damacaiborderclass {
    font-size: 17px;
  }
  .dragonborderclass {
    font-size: 17px;
  }
  .sabahborderclass {
    font-size: 17px;
  }
  .sarawakborderclass {
    font-size: 17px;
  }
  .totoborderclass {
    font-size: 17px;
  }
  .bordersingaporeclass {
    font-size: 17px;
  }
  .sandagenborderclass {
    font-size: 17px;
  }
  .number5Dborderdspecon {
    font-size: 19px;
  }
  .number6Dborderdspecon {
    font-size: 17px;
  }
  .americaborder {
    font-size: 17px;
  }
  .americaborder5D2 {
    font-size: 17px;
  }
  .bordermagnumclass {
    font-size: 17px;
  }
  .textalignment {
    font-size: 22px;
  }
  .textalignmen3t {
    font-size: 20px;
  }
  .numberborderdspecon {
    font-size: 23px;
  }
  .numberbordergorjackport {
    font-size: 23px;
  }
  .numberborder {
    font-size: 32px;
  }
  .numberborder2 {
    font-size: 27px;
  }
  .Animallogo2 {
    width: 100px;
    height: 120px;
  }
  .zodiacfont {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .americaborderclass {
    font-size: 14px;
  }
  .damacaiborderclass {
    font-size: 14px;
  }
  .logomagnumimages {
    width: 140px;
    height: 70px;
  }
  .dragonborderclass {
    font-size: 14px;
  }
  .sabahborderclass {
    font-size: 14px;
  }
  .sarawakborderclass {
    font-size: 14px;
  }
  .totoborderclass {
    font-size: 14px;
  }
  .bordersingaporeclass {
    font-size: 14px;
  }
  .sandagenborderclass {
    font-size: 14px;
  }
  .bordermagnumclass {
    font-size: 14px;
  }
  .textalignment {
    font-size: 20px;
  }
  .textalignmen3t {
    font-size: 18px;
  }
  .numberborderdspecon {
    font-size: 21px;
  }
  .numberbordergorjackport {
    font-size: 21px;
  }
  .number5Dborderdspecon {
    font-size: 17px;
  }
  .number6Dborderdspecon {
    font-size: 16px;
  }
  .americaborder {
    font-size: 16px;
  }
  .americaborder5D2 {
    font-size: 16px;
  }
  .numberborder {
    font-size: 30px;
  }
  .numberborder2 {
    font-size: 25px;
  }
  .Animallogo2 {
    width: 80px;
    height: 100px;
  }
  .zodiacfont {
    font-size: 16px;
  }
}

@media (max-width: 1400px) {
  .numberborderdspecon {
    font-size: 18px;
  }
  .logomagnumimages {
    width: 110px;
    height: 60px;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }
  .numberbordergorjackport {
    font-size: 18px;
  }
  .americaborder5D2 {
    font-size: 14px;
  }
  .numberborder {
    font-size: 27px;
  }
  .numberborder2 {
    font-size: 22px;
  }
  .number6Dborderdspecon {
    font-size: 16px;
  }
  .Animallogo2 {
    width: 80px;
    height: 90px;
  }
  .zodiacfont {
    font-size: 14px;
  }
}

@media (max-width: 1200px) {
  .americaborderclass {
    font-size: 14px;
  }
  .damacaiborderclass {
    font-size: 14px;
  }
  .dragonborderclass {
    font-size: 14px;
  }
  .bordermagnumclass {
    font-size: 14px;
  }
  .sarawakborderclass {
    font-size: 14px;
  }
  .totoborderclass {
    font-size: 14px;
  }
  .logomagnumimages {
    width: 90px;
    height: 50px;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }
  .bordersingaporeclass {
    font-size: 14px;
  }
  .sandagenborderclass {
    font-size: 14px;
  }
  .sabahborderclass {
    font-size: 14px;
  }
  .textalignment {
    font-size: 18px;
  }
  .textalignmen3t {
    font-size: 16px;
  }
  .numberborderdspecon {
    font-size: 16px;
  }
  .americaborder {
    font-size: 15px;
  }
  .americaborder5D2 {
    font-size: 14px;
  }
  .number5Dborderdspecon {
    font-size: 18px;
  }
  .number6Dborderdspecon {
    font-size: 16px;
  }
  .numberbordergorjackport {
    font-size: 16px;
  }
  .numberborder {
    font-size: 25px;
  }
  .numberborder2 {
    font-size: 20px;
  }
  .Animallogo2 {
    width: 60px;
    height: 80px;
  }
  .zodiacfont {
    font-size: 13px;
  }
}

@media (max-width: 1000px) {
  .numberborderdspecon {
    font-size: 15px;
  }
  .numberborder {
    font-size: 23px;
  }
  .Marginout {
    margin: 0 !important;
  }
  .number6Dborderdspecon {
    font-size: 16px;
  }
  .textalignment {
    font-size: 20px;
  }
  .Animallogo2 {
    width: 80px;
    height: 100px;
  }
  .zodiacfont {
    font-size: 14px;
  }
}

@media (min-width: 1800px) {
  .damacaiborderclass {
    font-size: 17px;
  }
  .textalignment {
    font-size: 23px;
  }
  .numberborderdspecon {
    font-size: 24px;
  }
  .number6Dborderdspecon {
    font-size: 20px;
  }
}
.react-datepicker input[readonly] {
  cursor: pointer;
}

.maxWidth100 {
  max-width: 100% !important;
}

.mobileHeaderlogo {
  height: 60px;
  width: 80px;
}

.br23 {
  border-radius: 2.3rem;
  background-color: transparent;
}
